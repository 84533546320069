<template lang="pug">
  v-container
    v-row(
      justify="center"
    )
      v-col(
        cols="12"
        md="8"
        lg="6"
      )
        h1.text-h4.mb-4 {{ verbage }} Shop
        v-card(
          outlined
        )
          v-card-text
            FeathersVuexFormWrapper(
              :item="item"
            )
              template(v-slot="{ clone, save, reset, isDirty }")
                v-form(
                  @submit.prevent="save().then(handleSaveReponse)"
                  ref="form"
                  v-model="valid"
                )
                  v-row
                    v-col
                      v-text-field(
                        label="Name"
                        filled
                        v-model.trim="clone.name"
                        :rules="[rules.required]"
                      )

                  v-row(
                    v-if="user.isAdmin"
                  )
                    v-col
                      RegionsAutocomplete(
                        :rules="[rules.required]"
                        v-model="clone.region_id"
                        :query="{ status: 'ACTIVE' }"
                      )
                  v-row
                    v-col
                      v-text-field(
                        label="Address 1"
                        filled
                        v-model.trim="clone.address1"
                        :rules="[rules.required]"
                      )

                  v-row
                    v-col
                      v-text-field(
                        label="Address 2"
                        filled
                        v-model.trim="clone.address2"
                      )

                  v-row
                    v-col.grow(
                      cols="12"
                      md="auto"
                    )
                      v-text-field(
                        label="City"
                        filled
                        v-model.trim="clone.city"
                        :rules="[rules.required]"
                      )
                    v-col.shrink(
                      cols="12"
                      md="3"
                    )
                      v-autocomplete(
                        label="State"
                        :items="statesAbbreviated"
                        v-model="clone.state"
                        :rules="[rules.required]"
                        filled
                        min-width="50px"
                      )

                  v-row
                    v-col
                      v-text-field(
                        label="ZIP"
                        filled
                        v-model.trim="clone.zip"
                        :rules="[rules.required]"
                      )

                  v-row(
                    v-if="user.isAdmin"
                  )
                    v-col
                      v-switch(
                        label="Enable CCC1 for this Shop"
                        v-model="clone.exportCCC1"
                      )

                  v-row
                    v-col
                      v-text-field(
                        inputmode="numeric"
                        label="CCC1 License Number"
                        filled
                        v-model.trim="clone.ccc1LicenseNo"
                        hint="Optional"
                        persistent-hint
                      )

                  v-row(
                    v-if="user.isAdmin"
                  )
                    v-col
                      v-switch(
                        label="Enable Invoicing"
                        v-model="clone.usesInvoicing"
                      )

                  v-row(
                    v-if="user.isAdmin"
                  )
                    v-col
                      v-switch(
                        label="Enable Ordering"
                        v-model="clone.usesOrdering"
                      )
                      v-text-field(
                        v-if="clone.usesOrdering === true"
                        label="Shop Email"
                        filled
                        type="email"
                        v-model.trim="verbage === 'Add' ? orderingEmail : clone.orderingEmail"
                        :rules="[rules.orderingEmail]"
                        hint="Optional"
                        persistent-hint
                      )

                  v-row
                    v-col
                      v-btn.mb-5(
                        color="secondary"
                        type="submit"
                        block
                        height="50px"
                        :disabled="!valid || !isDirty"
                      ) {{ verbage }} Shop
                      v-btn(
                        block
                        @click="cancel"
                      ) Cancel
</template>

<script>
import { FeathersVuexFormWrapper } from 'feathers-vuex'
import validations from '@/mixins/validationRulesMixin'
import RegionsAutocomplete from '@/components/RegionsAutocomplete'
export default {
  name: 'EditShop',
  components: {
    FeathersVuexFormWrapper,
    RegionsAutocomplete
  },
  mixins: [
    validations
  ],
  computed: {
    user () {
      return this.$store.getters['auth/user']
    },
    id () {
      return this.$route.params.shopId
    },
    regionId () {
      return this.$route.query.regionId
    },
    verbage () {
      return this.id === 'new' ? 'Add' : 'Edit'
    },
    // Returns a new Shop if the route `id` is 'new', or returns an existing Shop.
    item () {
      const { Shop } = this.$FeathersVuex.api

      return this.id === 'new' ? new Shop({ region_id: this.regionId }) : Shop.getFromStore(this.id)
    },
    orderingEmail () {
      return 'orders@aspclips.com'
    }
  },
  watch: {
    id: {
      handler (val) {
        // Early return if the route `:id` is 'new'
        if (val === 'new') {
          return
        }
        const { Shop } = this.$FeathersVuex.api
        const existingRecord = Shop.getFromStore(val)

        // If the record doesn't exist, fetch it from the API server
        // The `item` getter will automatically update after the data arrives.
        if (!existingRecord) {
          Shop.get(val)
        }
      },
      // We want the above handler handler to run immediately when the component is created.
      immediate: true
    }
  },
  methods: {
    handleSaveReponse (savedShop) {
      this.$router.push({ name: 'shop', params: { shopId: savedShop.id } })
    },
    cancel () {
      if (this.id === 'new') {
        this.$router.push({ name: 'shops' })
      } else {
        this.$router.push({ name: 'shop', params: { shopId: this.id } })
      }
    }
  }
}
</script>
